.reactInstagramCarousel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex; }
  .reactInstagramCarousel__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center; }
  .reactInstagramCarousel .reactInstagramCarousel__skip--left, .reactInstagramCarousel .reactInstagramCarousel__skip--right {
    position: relative;
    width: 50%;
    height: 100%; }
  .reactInstagramCarousel__progressBarSpace {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .reactInstagramCarousel__progressBar {
    position: relative;
    width: auto;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 2%;
    overflow: hidden; }
    .reactInstagramCarousel__progressBar--load {
      position: relative;
      width: 0;
      height: 100%;
      background-color: white; }
